import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class UpcomingProductV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__upcoming-project-area section-bg-1--- bg-image-top pt-115 pb-65" data-bs-bg={publicUrl+"assets/img/starter/02_5.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center---">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- white-color">Popular Projects</h6>
			          <h1 className="section-title  white-color">Dream Living Space <br />
			            Setting New Standards</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__upcoming-project-slider-1-active slick-arrow-3">
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/starter/02_6 square.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="section-subtitle ltn__secondary-color mb-0">Perfect beginning for new homeowners.</h6>
			                <h1 className="mb-30">Starter Homes</h1>
			                <ul className="mt">
			                  <li>1. Title Deeds: <span>Included</span></li>
			                  <li>2. Project Type: <span>Home</span></li>
			                  <li>3. Building Location: <span>Palm City, Alberton</span></li>
			                  <li>4. Starting At: <span>R460,000</span></li>
							  <li>5. Monthly Installment: <span>R4600</span></li>
			                </ul>
			                <div className="btn-wrapper animated go-top">
			                  <Link to="/property-details/6501833af535207f99830fd1" className="theme-btn-1 btn btn-effect-1">View Properties</Link>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/houses/green.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="ltn__secondary-color">The perfect place to live for families</h6>
			                <h1>Palm City</h1>
			                <ul>
							<li>1. Title Deeds: <span>Included</span></li>
			                  <li>2. Project Type: <span>Home</span></li>
			                  <li>3. Building Location: <span>Palm City, Alberton</span></li>
			                  <li>4. Starting At: <span>R650,000</span></li>
							  <li>5. Monthly Installment: <span> R6952</span></li>
			                </ul>
			                <div className="btn-wrapper animated go-top">
			                  <Link to="/shop" className="theme-btn-1 btn btn-effect-1">View Properties</Link>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default UpcomingProductV1