import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Product_Details = () => {
    const [propertyData, setPropertyData] = useState(null);
    
    // Use useParams to get the ID from the URL
    const { id } = useParams();

    useEffect(() => {
        // Use the retrieved ID in the fetch endpoint
        fetch(`https://api.affordable-housing.co.za/api/shopDetails/${id}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setPropertyData(data);
            })
            .catch((error) => {
                console.error('Error fetching propertyData data:', error);
            });
    }, [id]);  // Add `id` as a dependency to re-run the effect if the ID changes

    if (!propertyData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Navbar />
            <PageHeader headertitle="Property Details" customclass="mb-0" />
            
            {/* Pass propertyData as props to the components */}
            <ProductSlider gallery={propertyData.gallery} />
            <ProductDetails propertyData={propertyData} />
            
            <CallToActionV1 />
            <Footer />
        </div>
    );
}

export default Product_Details;
