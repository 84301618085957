import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ApartmentV2 extends Component {

    render() {

	let CustomClass = this.props.customClass ? this.props.customClass : ''

    let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className={"ltn__apartments-plan-area pt-115--- pb-70 "+ CustomClass}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Expertly Crafted</h6>
			          <h1 className="section-title">Floor Plans</h1>
			        </div>
			        <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
			          <div className="nav">
			            <a className="active show" data-bs-toggle="tab" href="#liton_tab_3_1">Starter Home</a>
			            <a  data-bs-toggle="tab" href="#liton_tab_3_2">Palm City 45m²</a>
			            <a data-bs-toggle="tab" href="#liton_tab_3_3">Palm City 50m²</a>
			            <a data-bs-toggle="tab" href="#liton_tab_3_4">Palm City 55m²</a>
			            <a data-bs-toggle="tab" href="#liton_tab_3_5">Palm City 58m²</a>
						<a data-bs-toggle="tab" href="#liton_tab_3_6">Palm City 63m²</a>
			          </div>
			        </div>
			        <div className="tab-content">
			          <div className="tab-pane fade active show" id="liton_tab_3_1">
			            <div className="ltn__apartments-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
			                    <h2>Starter Home</h2>
			                    <p>Welcome to Kiron Properties' exclusive Starter Homes in Palm City, Alberton! 
									Available to the public for the first time, we offer you quality homes at unbeatable prices, 
									starting at just R460,000.</p>
			                    <div className="apartments-info-list apartments-info-list-color mt-40">
			                      <ul>
			                        <li><label>Size</label> <span>58m²</span></li>
			                        <li><label>Bedroom</label> <span>2</span></li>
			                        <li><label>Bathroom</label> <span>1</span></li>
			                        <li><label>Kitchen</label> <span>1</span></li>
			                        <li><label>Lounge</label> <span>1</span></li>
									<li><label>Total Price</label> <span>R460,000</span></li>
									<li><label>Monthly Amount</label> <span>R4600</span></li>
			                      </ul>
			                    </div>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/starter/starter-bp.jpg"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade" id="liton_tab_3_2">
			            <div className="ltn__product-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
			                    <h2>Palm City 45m²</h2>
			                    <p>Discover a place to call home in Palm City, located in the vibrant neighborhood of Alberton Ekurhuleni. 
									Our exquisite 2-bedroom, 1-bathroom homes are designed with modern living in mind. 
									Experience the open-plan layout and well-appointed kitchenette, creating the perfect space for you and your loved ones.</p>
			                    <div className="apartments-info-list apartments-info-list-color mt-40">
			                      <ul>
								  <li><label>Size</label> <span>45m²</span></li>
			                        <li><label>Bedroom</label> <span>2</span></li>
			                        <li><label>Bathroom</label> <span>1</span></li>
			                        <li><label>Kitchen</label> <span>1</span></li>
			                        <li><label>Lounge</label> <span>1</span></li>
									<li><label>Total Price</label> <span>R650,000</span></li>
									<li><label>Monthly Amount</label> <span>R6952</span></li>
			                      </ul>
			                    </div>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/houses/Palm-City-Legend-Property-1-bp.jpg"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade" id="liton_tab_3_3">
			            <div className="ltn__product-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
			                    <h2>Palm City 50m²</h2>
			                    <p>Discover a place to call home in Palm City, located in the vibrant neighborhood of Alberton Ekurhuleni. 
									Our exquisite 2-bedroom, 1-bathroom homes are designed with modern living in mind. 
									Experience the open-plan layout and well-appointed kitchenette, creating the perfect space for you and your loved ones.</p>
			                    <div className="apartments-info-list apartments-info-list-color mt-40">
			                      <ul>
								  <li><label>Size</label> <span>50m²</span></li>
			                        <li><label>Bedroom</label> <span>2</span></li>
			                        <li><label>Bathroom</label> <span>1</span></li>
			                        <li><label>Kitchen</label> <span>1</span></li>
			                        <li><label>Lounge</label> <span>1</span></li>
									<li><label>Total Price</label> <span>R650,000</span></li>
									<li><label>Monthly Amount</label> <span>R7267</span></li>
			                      </ul>
			                    </div>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/houses/Palm-City-Legend-Property-2-bp.jpg"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade" id="liton_tab_3_4">
			            <div className="ltn__product-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
			                    <h2>Palm City 55m²</h2>
			                    <p>Experience the epitome of contemporary living in Palm City, an exquisite new-build development designed for families and couples. 
									With 3 bedrooms and 1 bathrooms, these homes feature open-plan living areas with kitchenettes, perfectly suited for modern lifestyles.
									</p>
			                    <div className="apartments-info-list apartments-info-list-color mt-40">
								<ul>
								  <li><label>Size</label> <span>55m²</span></li>
			                        <li><label>Bedroom</label> <span>3</span></li>
			                        <li><label>Bathroom</label> <span>1</span></li>
			                        <li><label>Kitchen</label> <span>1</span></li>
			                        <li><label>Lounge</label> <span>1</span></li>
									<li><label>Total Price</label> <span>R710,000</span></li>
									<li><label>Monthly Amount</label> <span>R7582</span></li>
			                      </ul>
			                    </div>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/houses/Palm-City-Property-for-sale-7-bp.jpg"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade" id="liton_tab_3_5">
			            <div className="ltn__product-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
			                    <h2>Palm City 58m²</h2>
			                    <p>Experience the essence of contemporary living in this stunning new-build development crafted for families and couples. 
									With 3 bedrooms and 2 bathrooms, these homes offer spacious open-plan living areas with well-designed kitchenettes, perfectly suited for modern lifestyles.</p>
			                    <div className="apartments-info-list apartments-info-list-color mt-40">
								<ul>
								  <li><label>Size</label> <span>58m²</span></li>
			                        <li><label>Bedroom</label> <span>3</span></li>
			                        <li><label>Bathroom</label> <span>2</span></li>
			                        <li><label>Kitchen</label> <span>1</span></li>
			                        <li><label>Lounge</label> <span>1</span></li>
									<li><label>Total Price</label> <span>R745,000</span></li>
									<li><label>Monthly Amount</label> <span>R7949</span></li>
			                      </ul>
			                    </div>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/houses/Palm-City-Houses-for-sale-1-bp.jpg"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
					  <div className="tab-pane fade" id="liton_tab_3_6">
			            <div className="ltn__product-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg--- section-bg-1 text-color-white---">
			                    <h2>Palm City 63m²</h2>
			                    <p>Experience the ideal home for young families and couples. 
									This affordable 3-bedroom house with 2 bathrooms offers a seamless open-plan living space, 
									with a stylish kitchenette that effortlessly integrates with the living area.</p>
			                    <div className="apartments-info-list apartments-info-list-color mt-40">
								<ul>
								  <li><label>Size</label> <span>63m²</span></li>
			                        <li><label>Bedroom</label> <span>3</span></li>
			                        <li><label>Bathroom</label> <span>2</span></li>
			                        <li><label>Kitchen</label> <span>1</span></li>
			                        <li><label>Lounge</label> <span>1</span></li>
									<li><label>Total Price</label> <span>R785,000</span></li>
									<li><label>Monthly Amount</label> <span>R8369</span></li>
			                      </ul>
			                    </div>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/houses/Houses-for-sale-palm-city-1-63-bp.jpg"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default ApartmentV2