import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios';

class ContactForm extends Component {

    handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            service: formData.get('service'),
            phone: formData.get('phone'),
            message: formData.get('message')
        };

        try {
            const response = await axios.post('https://api.affordable-housing.co.za/api/mail/send-email', data);
            console.log("Form submitted successfully:", response.data); // Log successful form submission
            // Handle success - for example, display a success message or clear the form
        } catch (error) {
            console.error("Form submission failed:", error); // Log failure of form submission
            // Handle error - for example, display an error message to the user
        }
    }
    render() {

	let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-message-area mb-120 mb--100">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="ltn__form-box contact-form-box box-shadow white-bg">
						<h4 className="title-2">Contact Us</h4>
						<form id="contact-form" onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-md-6">
							<div className="input-item input-item-name ltn__custom-icon">
								<input type="text" name="name" placeholder="Enter your name" />
							</div>
							</div>
							<div className="col-md-6">
							<div className="input-item input-item-email ltn__custom-icon">
								<input type="email" name="email" placeholder="Enter email address" />
							</div>
							</div>
							<div className="col-md-6">
							<div className="input-item" >
								<select className="nice-select" name="service">
								<option>Select Service Type</option>
								<option>Starter Homes </option>
								<option>Home Buying</option>
								<option>Home Rentals</option>
								<option>Bank Benefits</option>

								</select>
							</div>
							</div>
							<div className="col-md-6">
							<div className="input-item input-item-phone ltn__custom-icon">
								<input type="text" name="phone" placeholder="Enter phone number" />
							</div>
							</div>
						</div>
						<div className="input-item input-item-textarea ltn__custom-icon">
							<textarea name="message" placeholder="Enter message" defaultValue={""} />
						</div>
						<p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label></p>
						<div className="btn-wrapper mt-0">
							<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Send Message</button>
						</div>
						<p className="form-messege mb-0 mt-20" />
						</form>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactForm