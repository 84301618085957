import React, { Component } from 'react';

class Sidebar extends Component {

    handleCheckboxChange = (filterCategory, filterValue, event) => {
        if (this.props.onFilterChange) {
            this.props.onFilterChange(filterCategory, filterValue, event.target.checked);
        }
    }

    render() {
		const { products } = this.props; // Get the products array from props
        const totalResults = products.length; // Calculate total results

        return (
            <div className="col-lg-4  mb-100">
                <aside className="sidebar ltn__shop-sidebar">
                    <h3 className="mb-10">Filters</h3>
                    <label className="mb-30"><small>{totalResults} results </small></label>
                    {/* Advance Information widget */}
                    <div className="widget ltn__menu-widget">
                        <h4 className="ltn__widget-title">Property Type</h4>
                        <ul>
                            {/* <li>
                                <label className="checkbox-item">House
                                    <input 
                                        type="checkbox"
                                     defaultChecked="checked"
                                        onChange={e => this.handleCheckboxChange('propertyType', 'House', e)}
                                    />
                                    <span className="checkmark" />
                                </label>
                            </li> */}
                            <li>
                                <label className="checkbox-item">Starter Home
                                    <input 
                                        type="checkbox"
                                        onChange={e => this.handleCheckboxChange('propertyType', 'Starter Home', e)}
                                    />
                                    <span className="checkmark" />
                                </label>
                            </li>
                        </ul>
<br></br>
                        <h4 className="ltn__widget-title">Features</h4>
                        <ul>
                            {["Community-Feel", "Private School", "Private Clinic", "Sports Field", "Nearby Shopping Centres", "Easy access to major road networks"].map((amenity, index) => (
                                <li key={index}>
                                    <label className="checkbox-item">{amenity}
                                        <input 
                                            type="checkbox"
                                            onChange={e => this.handleCheckboxChange('amenities', amenity, e)}
                                        />
                                        <span className="checkmark" />
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* Other widgets... */}
                </aside>
            </div>
        );
    }
}

export default Sidebar;