import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ProductSliderV1 extends Component {
    render() {
        const { gallery } = this.props;

        // Check if gallery is an array
        if (!Array.isArray(gallery)) {
            return null; // Render nothing if gallery is not an array
        }

        return (
            <div className="ltn__img-slider-area mb-90">
                <div className="container-fluid">
                    <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
                        {gallery.map((image, index) => (
                            <div className="col-lg-12" key={index}>
                                <div className="ltn__img-slide-item-4">
                                    <a href={image} data-rel="lightcase:myCollection">
                                        <img src={image} alt={`Image ${index}`} />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductSliderV1;
