import React from 'react';
import { Link } from 'react-router-dom';

const ShopDetails = ({ propertyData }) => {
    if (!propertyData) {
        return <div>Loading...</div>;
    }

    const {
        name,
        date,
        location,
        description,
        propertyDetails,
        gallery,
        amenities,
        locationMap,
        floorPlans,
        author,
        featured,
    } = propertyData;


    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
      };

    const formattedDate = formatDate(date);
    const publicUrl = process.env.PUBLIC_URL + '/';

    const amenitiesArray = Object.entries(amenities);

    return (
        <div className="ltn__shop-details-area pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <div className="ltn__blog-meta">
                                <ul>
                                    <li className="ltn__blog-category">
                                        <Link to="#">Featured</Link>
                                    </li>
                                    <li className="ltn__blog-category">
                                        <Link className="bg-orange" to="#">
                                        {featured}
                                        </Link>
                                    </li>
                                    <li className="ltn__blog-date">
                                        <i className="far fa-calendar-alt" /> {formattedDate}
                                    </li>
                                </ul>
                            </div>
                            <h1>{name}</h1>
                            <label>
                                <span className="ltn__secondary-color">
                                    <i className="flaticon-pin" />
                                </span>{' '}
                                {location}
                            </label>
                            <h4 className="title-2">Description</h4>
                            <p>{description}</p>
                            <h4 className="title-2">Property Details</h4>
                            <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                                <ul>
                                    <li>
                                        <label>Property ID:</label> <span>{propertyDetails.propertyID}</span>
                                    </li>
                                    <li>
                                        <label>Price: </label> <span>{propertyDetails.price}</span>
                                    </li>
                                    <li>
                                        <label>Monthly Price:</label> <span>{propertyDetails.monthly}</span>
                                    </li>
                                    <li>
                                        <label>Property Type:</label> <span>{propertyDetails.type}</span>
                                    </li>
                                    <li>
                                        <label>Property Status:</label> <span>{propertyDetails.status}</span>
                                    </li>
                                    <li>
                                        <label>Bedrooms:</label> <span>{propertyDetails.bedrooms}</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <label>Bathrooms:</label> <span>{propertyDetails.bathrooms}</span>
                                    </li>
                                    <li>
                                        <label>Size:</label> <span>{propertyDetails.size}</span>
                                    </li>
                                    <li>
                                        <label>Land Area:</label> <span>{propertyDetails.landArea}</span>
                                    </li>
                                    <li>
                                        <label>Title Deeds:</label> <span>{propertyDetails.titleDeeds}</span>
                                    </li>
                                </ul>
                            </div>
                            <h4 className="title-2">From Our Gallery</h4>
                            <div className="property-details-gallery mb-30">
                                <div className="row">
                                    {gallery.map((image, index) => (
                                        <div className="col-md-6" key={index}>
                                            <a href={image} data-rel="lightcase:myCollection">
                                                <img className="mb-30" src={image} alt={`Gallery ${index}`} />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <h4 className="title-2 mb-10">Amenities</h4>
                            <div className="property-details-amenities mb-60">
                                <div className="row">
                                    {amenitiesArray.map(([key, value], index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <div className="ltn__menu-widget">
                                                <ul>
                                                    <li>
                                                        <label className="checkbox-item">
                                                            {key}
                                                            <input type="checkbox" defaultChecked={value} disabled />
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <h4 className="title-2">Location</h4>
                            <div className="property-details-google-map mb-60">
                                <iframe
                                    src={locationMap}
                                    title="Property Location"
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    allowFullScreen
                                    aria-hidden="false"
                                    tabIndex="0"
                                />
                            </div>
                            <h4 className="title-2">Floor Plans</h4>
                            <div className="apartments-plan-area product-details-apartments-plan mb-60">
                                <div className="tab-menu tab-menu-3 tab-menu-top-right-- text-uppercase--- text-center---">
                                    <div className="nav">
                                        {floorPlans.map((plan, index) => (
                                            <a
                                                key={index}
                                                className={index === 0 ? 'active show' : ''}
                                                data-bs-toggle="tab"
                                                href={`#liton_tab_3_${index + 1}`}
                                            >
                                                {plan.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                                <div className="tab-content">
                                    {floorPlans.map((plan, index) => (
                                        <div
                                            key={index}
                                            className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                                            id={`liton_tab_3_${index + 1}`}
                                        >
                                            <div className="apartments-tab-content-inner">
                                                <div className="row">
                                                    <div className="col-lg-7">
                                                        <div className="apartments-plan-img">
                                                            <img src={plan.image} alt={`Floor Plan ${index}`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                            <h2>{plan.name}</h2>
                                                            <p>{plan.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="product-details-apartments-info-list section-bg-1">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                        <ul>
                                                                            <li>
                                                                                <label>Total Area</label> <span>{plan.totalArea}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label>House Area</label> <span>{plan.houseArea}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="apartments-info-list apartments-info-list-color mt-40---">
                                                                        <ul>
                                                                            <li>
                                                                                <label>Parking</label> <span>{plan.parking}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label>Title Deeds</label> <span>{plan.titleDeeds}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                            <div className="widget ltn__author-widget">
                                <div className="ltn__author-widget-inner text-center">
                                    <img src={author.image} alt="Author" />
                                    <h5>{author.name}</h5>
                                    <small>{author.designation}</small>
                                    <div className="product-ratting">
                                        <ul>
                                            {Array.from({ length: author.rating }, (_, index) => (
                                                <li key={index}>
                                                    <a href="#">
                                                        <i className="fas fa-star" />
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <p>{author.description}</p>
                                    <div className="ltn__social-media">
                                        <ul>
                                            <li>
                                                <a href="#" title="Facebook">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="Twitter">
                                                    <i className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="Linkedin">
                                                    <i className="fab fa-linkedin" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" title="Youtube">
                                                    <i className="fab fa-youtube" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="widget ltn__form-widget">
                                <h4 className="ltn__widget-title ltn__widget-title-border-2">Request a Call-back</h4>
                                <form action="#">
                                    <input type="text" name="yourname" placeholder="Your Name*" />
                                    <input type="text" name="yournumber" placeholder="Your Number*" />
                                    <textarea name="yourmessage" placeholder="Write Message..." defaultValue={''} />
                                    <button type="submit" className="btn theme-btn-1">
                                        Send Message
                                    </button>
                                </form>
                            </div>
                            <div className="widget ltn__banner-widget d-none go-top">
                                <Link to="/shop">
                                    <img src={publicUrl + 'assets/img/banner/2.jpg'} alt="Banner" />
                                </Link>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopDetails;
