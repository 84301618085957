import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class StarterHomes extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__about-us-area section-bg-1 bg-image-right-before pt-120 pb-90">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-20">
			            <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Starter Homes</h6>
			            <h1 className="section-title">Making living spaces
			              More Affordable</h1>
			            <p>Welcome to Kiron Properties' exclusive Starter Homes in Palm Ridge, Alberton! Available to the public for the first time, we offer you quality homes at unbeatable prices, starting at just R460,000.</p>
			          </div>
			          <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
			            <li>
			              <i className="icon-done" />
			              Homes priced from R460,000 with monthly repayments starting at R4600
			            </li>
			            <li>
			              <i className="icon-done" />
			              A fully serviced stand with a 2-bedroom house.
			            </li>
			            <li>
			              <i className="icon-done" />
			              Transfer and Occupational costs are all on us!
			            </li>
			            <li>
			              <i className="icon-done" />
			              Full Title Deeds provided.
			            </li>
			            <li>
			              <i className="icon-done" />
			              Nestled in Palm Ridge, a growing suburb promising future potential.
			            </li>
			            <li>
			              <i className="icon-done" />
						  An intentional 22 m² foundation space, ready for your unique touch.
			            </li>
			          </ul>
			          <div className="  ltn__animation-pulse2 text-center mt-30">
			            <a className="ltn__video-play-btn bg-white--- ltn__secondary-bg" href={publicUrl+"assets/media/ah.mp4"} data-rel="lightcase">
			              <i className="icon-play  ltn__secondary-color--- white-color" />
			            </a>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default StarterHomes