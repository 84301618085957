import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './shop-sidebar';

class ShopGridV1 extends Component {
    state = {
        products: [],
        isLoading: true,
        error: null,
        filters: {
            propertyType: [],
            amenities: [],
        },
    };

    componentDidMount() {
        this.fetchProducts();
    }

    handleFilterChange = (filterCategory, filterValue, isChecked) => {
        this.setState(
            (prevState) => {
                const { filters } = prevState;
                if (isChecked) {
                    if (!filters[filterCategory]) {
                        filters[filterCategory] = [];
                    }
                    filters[filterCategory].push(filterValue);
                } else {
                    const index = filters[filterCategory].indexOf(filterValue);
                    if (index > -1) {
                        filters[filterCategory].splice(index, 1);
                    }
                }
                return {
                    filters: filters,
                };
            },
            () => {
                this.fetchProductsBasedOnFilters();
            }
        );
    };

    fetchProductsBasedOnFilters = () => {
        const { filters } = this.state;

        const queryParams = Object.keys(filters)
            .map((filterCategory) => {
                if (filters[filterCategory].length > 0) {
                    return `${filterCategory}=${filters[filterCategory].join(',')}`;
                }
                return null;
            })
            .filter((queryParam) => queryParam !== null)
            .join('&');

        fetch(`https://api.affordable-housing.co.za/api/shopDetails/all?${queryParams}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Fetched products:', data);
                this.setState({ products: data, isLoading: false });
            })
            .catch((error) => {
                this.setState({ error, isLoading: false });
            });
    };

    fetchProducts = () => {
        fetch('https://api.affordable-housing.co.za/api/shopDetails/all')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log('Fetched products:', data);
                this.setState({ products: data, isLoading: false });
  
            })
            .catch((error) => {
                this.setState({ error, isLoading: false });
            });
    };

    render() {
        let publicUrl = process.env.PUBLIC_URL || 'https://affordable-housing.co.za' + '/';
        const { products, isLoading, error } = this.state;
        const totalResults = products.length; // Calculate total results

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        }

        return (
            <div>
                <div className="ltn__product-area ltn__product-gutter mb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8  mb-100">
                                <div className="ltn__shop-options">
                                    <div className="col-lg-12">
                                        <div className="ltn__shop-options">
                                            <ul>
                                                <li>
                                                    <div className="showing-product-number text-right">
                                                        <span>Showing {totalResults} results</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content ">
                                            <div className="tab-pane fade active show" id="liton_product_grid">
                                                <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                                                    <div className="row">
                                                        {products.map((product) => (
                                                            <div className="col-lg-4 col-sm-6 col-12" key={product._id}>
                                                                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                                                                    {/* <div className="product-img"> */}
                                                                        <div className="product-image-wrapper">
                                                                        <Link to={`/property-details/${product._id}`}><img src={publicUrl + product.gallery[0]} alt={product.name} /></Link>
                                                                        </div>
                                                                        <div className="real-estate-agent">
                                                                            <div className="agent-img">
                                                                                <Link to={`/property-details/${product._id}`}><img src={publicUrl + product.author.image} alt={product.agentName} /></Link>
                                                                            </div>
                                                                        </div>
                                                                    {/* </div> */}
                                                                    {/* More code here using 'product' object properties */}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Sidebar
    onFilterChange={this.handleFilterChange}
    products={this.state.products} // Pass products from state to the Sidebar component
/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShopGridV1;
